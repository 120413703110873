const Labels = {
  Sidebar: {
    Hyperlink: "Hyperlink Management",
    configuration: "Configuration",
    Notification: "Notifications",
    Lookup: "Look up Management",
    Email: "Email Group Management",
    Template: "Template Management",
    Publish: "Publish Management",
  },
  Publish: {
    FieldAdd: "Fields Added / Deleted",
    emergencyEquipment: "Emergency Equipment",
    hazardousEnergies: "Hazardous Energies & Control",
    mutcd: "MUTCD Plan",
    potentialAoc: "Potential AOC List",
    ppe: "PPE",
    procedureMapping: "Procedure Mapping",
    swiMapping: "SWI Mapping",
  },
  TemplateCommon: {
    WorkLocation: "WorkLocation",
    IsMusterPoint: "IsMusterPoint",
    MusterPointLoc: "MusterPointLoc",
    Landmark: "Landmark",
    Datetime: "DateTime",
    Weather: "Weather",
    AdditionalHazards: "AdditionalHazards",
    MitigationMeasures: "MitigationMeasures",
    CellPhone: "CellPhone",
    RadioCheck: "RadioCheck",
    Radio: "Radio",
    Communication: "Communication",
    WorkLocationAddress: "WorkLocationAddress",
    CrewMembersDetail: "CrewMembersDetail",
    CrewOthersDetail: "CrewOthersDetail",
    NearestMedicalFacility: "NearestMedicalFacility",
    MedicalConsiderations: "MedicalConsiderations",
    Measures: "Measures",
    EmergencyEquipAvailable: "EmergencyEquipAvailable",
    EmergencyReview: "EmergencyReview",
    RescuePlan: "RescuePlan",
    MUTCDPlan: "MUTCDPlan",
    PublicSafety: "PublicSafety",
    Housekeeping: "Housekeeping",
    PPE: "PPE",
    PublicAccessSecured: "PublicAccessSecured",
    Sidewalks: "Sidewalks",
    SignsDeployed: "SignsDeployed",
    HousekeepingSite: "HousekeepingSite",
    HousekeepingUnique: "HousekeepingUnique",
    OtherTask: "OtherTask",
  },
  ElectricCommon: {
    ElectricCircuitInformation: "ElectricCircuitInformation",
    MultiCircuitExposure: "MultiCircuitExposure",
    Circuit2: "Circuit2",
    Circuit3: "Circuit3",
    Circuit4: "Circuit4",
    NoOfCircuits: "NoOfCircuits",
    FlaggersDetails: "FlaggersDetails",
    TrafficFlow: "TrafficFlow",
    BucketBoom: "BucketBoom",
    HeavyEquipment: "HeavyEquipment",
    Vehicle: "Vehicle",
    SpottersQualified: "SpottersQualified",
    MinimumDistance: "MinimumDistance",
    HPTools: "HPTools",
    ESOPLink: "ESOPLink",
    ExcavationActivities: "ExcavationActivities",
    SpecificHazards: "SpecificHazards",
    WorkIsEnergized: "WorkIsEnergized",
    GroundingRequirement: "GroundingRequirement",
    GroundingPlan: "GroundingPlan",
    PgMad: "PgMad",
    PpMad: "PpMad",
    LatentTraps: "LatentTraps",
    JobDescription: "JobDescription",
    ConfinedSpace: "ConfinedSpace",
    CBYDDigSafe: "CBYDDigSafe",
    EASupervisor: "EASupervisor",
    EDSupervisor: "EDSupervisor",
    MarkOutsValidation: "MarkOutsValidation",
    CableAvoidance: "CableAvoidance",
    ExcavationDepth: "ExcavationDepth",
    Identification: "Identification",
    IdentifiedSpotter: "IdentifiedSpotter",
    Verbal: "Verbal",
    HandSignal: "HandSignal",
    SWIJobs: "SWIJobs",
  },
  GasCommon: {
    MaterialType: "MaterialType",
    PressureType: "PressureType",
    UtilitiesPresent: "UtilitiesPresent",
    GasMeter: "GasMeter",
    WorkTask: "WorkTask",
    CS: "CS",
    Procedures: "Procedures",
    SignOff: "SignOff",
    AOC: "AOC",
    HazardControlPlan: "HazardControlPlan",
    EI: "EI",
    MarkOut: "MarkOut",
    CGIDate: "CGIDate",
    CGISerial: "CGISerial",
    Others: "Others",
    ProcedureRequired: "ProcedureRequired",
    JSPs: "JSPs",
    SecondJSPs: "SecondJSPs",
    Potential: "Potential",
    Asbestos: "Asbestos",
    PCBWork: "PCBWork",
    MRW: "MRW",
    SpillKits: "SpillKits",
    MaterialsAvailable: "MaterialsAvailable",
    Precautions: "Precautions",
    ControlRisk: "ControlRisk",
    GreatestRisk: "GreatestRisk",
  },
  SectionName: {
    GSI: "General Safety Information",
    MEI: "Medical & Emergency Information",
    WZS: "Work Zone Safety",
    JDHCP: "Job Desciption & Hazard Control Plan",
    ESCI: "Electric Source Control Information",
    HPT: "Human Performance Tools",
    OSF: "Other Safety factors",
    STS: "Site/Task Safety",
    AOC: "Procedures & AOCs",
    HEC: "Hazard Energies & Controls",
    EI: "Environmental Impacts",
    DCSQ: "Distribution/Construction Specific Questions",
    MSSQ: "Meter Service",
    IRSQ: "I&R Specific Questions",
  },
};

export default Labels;
